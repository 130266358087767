.the-box-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:1rem;
}
.the-box {
    flex: 1 1 200px;
    /*width: 35rem;*/
    height: 3rem;
    line-height: 4.5;
    background: #185901;
    display: block;
    text-align: center;
    font-size: 1rem;
    color: #FFF;
    text-transform: uppercase;
    margin: 2rem auto;
    cursor: pointer;
}

.the-box2 {
    background: #020159 !important;
    color: #FFF;
   
}
.the-box3 {
    background: #560108 !important;
    color: #FFF;
}
.the-box4 {
    background: #125601 !important;
    color: #ffffff;
}
